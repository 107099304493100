import React, { useState } from 'react';
import { Dropdown, ListGroupItem } from 'react-bootstrap';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';

import { firestore } from '../database/firebase';
import { GenericErrorAlert } from './alerts';
import { InsideCard } from './cards';
import { GenericLoadingAnimation } from './loadingAnimations';
import assist_config from '../assist_config.json';


const Faqlist = () => {
    const [value, loading, error] = useCollectionOnce(
        firestore.collection(assist_config.company).doc("assets").collection("faq").orderBy('created', 'desc').limit(5),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })

    if (loading) return (<GenericLoadingAnimation />)
    if (error) return <GenericLoadingAnimation title="Can't fetch faq." />

    return (
        <div>
            {value.docs.map(doc =>
                <InsideCard action key={doc.id} title={doc.data().question}>

                    {doc.data().answer}


                </InsideCard>
            )}
        </div>
    )
}


export { Faqlist };

