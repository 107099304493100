import React from 'react';
import { Nav, Navbar, Image } from 'react-bootstrap';
import whiteFullLogo from '../assets/06.png';
import discordLogo from '../assets/disc.png';

/**
 * Main navigation menu
 * @component
 *
 */

const FrontMenu = () => {
    return (
        <>
            <Navbar className="mb-4" >
            <Navbar.Brand>
            <Image onClick={()=> window.location.reload()}width="300px" aria-label="Mekanisk logo" fluid src={whiteFullLogo}></Image>
            </Navbar.Brand>
            </Navbar>
        </>
    )
}


const Footer = () => {
    return (
        <>
            <Navbar className="mt-4" >
            <Nav>
            <Nav.Link className="text-white">© Mekanisk 2021</Nav.Link>
          </Nav>
          
            <Nav>
            <Nav.Link className="text-white" onClick={() => window.open("https://mekanisk.com/")}>International: Mekanisk.com</Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link className="text-white" onClick={() => window.open("https://tastatur.no/")}>Norway: Tastatur.no</Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link className="text-white" onClick={() => window.open("https://mekanisk.com/pages/terms")}>Terms</Nav.Link>
          </Nav>
          

          <Navbar.Brand>
            <Image fluid width="100px" onClick={() => window.open("https://discord.com/invite/Wta8DER")} src={discordLogo}/>
          </Navbar.Brand>
            </Navbar>
        </>
    )
}

export { FrontMenu, Footer};
