import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import FrontPage from './pages/frontpage';


/**
 * Routing for the entire web application. The routing is wrapped in the AuthProvider connected to Firebase.
 * @component
 */

function App() {
  return (
    
      <Router>
        <div className="Mekanisk Assist">
          <Route exact path="/" component={FrontPage}/>
        </div>
      </Router>
    
  );
}

export default App;