import firebase from 'firebase/app';
import React, { useState } from 'react';
import { Accordion, Button, Col, Dropdown, DropdownButton, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';



const StandardCard = ({ children, title, subText }) => {
    return (
        <Card className="p-4 mt-2 shadow-sm">
            <Card.Title>{title}</Card.Title>
            <small className="text-muted">{subText}</small>
            {children}
        </Card>
    )
}

const InsideCard = ({ children, title }) => {
    return (
        <Card className="p-4 mb-4 mt-4 shadow-sm">
            <Card.Title>{title}</Card.Title>
            {children}
        </Card>
    )
}



export { StandardCard, InsideCard };

