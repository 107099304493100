import React, { useRef, useState } from 'react';
import { Button, Image, Nav, Navbar, Row, Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { StandardCard } from '../comp/cards';
import { CustomerForm } from '../comp/forms';
import { Faqlist } from '../comp/lists';
import { FrontMenu, Footer } from '../comp/menus';

/**
 * Front page of the web application
 * @component
 */

const FrontPage = ({ history }) => {

  const [stage, setStage] = useState(0)

  return (
    <>

    <FrontMenu/>
    <Container>
    <Row className="justify-content-md-center">


</Row>

    </Container>
      <Container>
        {stage === 0 ?

          <StandardCard title="Frequently asked questions">
            <Faqlist />
            <Button disabled onClick={() => setStage(1)}>I didn't find an answer</Button>
          </StandardCard> :

          <StandardCard title="Submit a new ticket">
            <CustomerForm><Button variant="danger" onClick={() => setStage(0)}>Return to FAQ</Button></CustomerForm>
          </StandardCard>
        }
          </Container>


        <Container>
        <br/>
        
        

      </Container>

      <Footer/>

    </>
  )
}

export default FrontPage;
