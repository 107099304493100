import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';





/**
 * Modal for reading the GDPR
 * @component
 *
 */

const GDPRmodal = ({show, setShow}) => {
  return(
    <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>GDPR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            We use your email in combination with your order number to save the ticket in our system. We will use your email to send you the initial
            email confirming ticket arrival. We will then use your email to contact you regarding this ticket if we need more information. At the end of the process
            we will use your email to send an email confirming that the ticket has been solved. 
            All the information provided to us is confidental, and will be saved 6 months or less from the ticket solved date.
        </Modal.Body>

      
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )
} 


export { GDPRmodal };
