import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const GenericLoadingAnimation = () => {
    return (
        <Spinner className="m-4" variant="dark" animation="border" />
    )
}

export { GenericLoadingAnimation };



