import "firebase/analytics";
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";


var config = {
  apiKey: "AIzaSyD7d2XcHQAFfUzZ5LWI70Gggk0ekf511v0",
  authDomain: "mekanisk-assist.firebaseapp.com",
  databaseURL: "https://mekanisk-assist.firebaseio.com",
  projectId: "mekanisk-assist",
  storageBucket: "mekanisk-assist.appspot.com",
  messagingSenderId: "663435338909",
  appId: "1:663435338909:web:f718d07e3060c9ed0ae074",
  measurementId: "G-K72ED46YDW"
};

/**
 * App is our Firebase initilzation.
 * @component
 */

const app = firebase.initializeApp(config)

/**
 * Firebase Storage
 * @component
 */
export const storage = firebase.storage()

/**
 * Firestore
 * @component
 */
export const firestore = firebase.firestore()

/**
 * Firebase Cloud Functions
 * @component
 */
export const functions = firebase.functions()
export { app };
